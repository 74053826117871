<template>
  <div class="tips bbox">
    <p class="title f18 fw700 textc pb30">
      <!-- {{ $t("regInstruct.RegistrationForNewUsers") }} -->
      用户注册协议
    </p>
    <div>
      <p class="texti2">
        欢迎来到爱科来国际贸易（上海）有限公司（以下简称"爱科来"）售后服务网站。通过爱科来提供的本网站（以下简称 “网站"），您可以享受爱科来提供的售后服务（以下简称"服务），并可以访问网站上的被授权的资源和内容（以下简称为“内容），其中包括：（a）网页、数据、消息、文本、图像、照片、图形、音频和视频以及文档（以下简称"材料"）：（b） 公告栏和其他服务。
      </p>
       <p class="texti2">
       以下是您与爱科来之间的法律协议。请确认您已充分阅读、理解并接受本协议的全部内容，同意遵循本协议的所有约定。如您不同意本协议随时对其的补充和修改，您可以主动停止使用爱科来提供的网站和服务；如果您继续浏览和使用本网站，则视为您已了解并完全同意遵守本协议的各项条款，包括爱科来对本协议所做的任何补充和修改。爱科来保留随时修改和实施这些条款的权利，修改后的用户注册协议和隐私声明在爱科来发布时立即生效。您可以在https://arkray-service.com.cn/#/advertising查看隐私声明的最新条款。
      </p>
      <p class="f14 fw700 mt30">1.协议适用主体范围</p>
      <p>
        爱科来的服务及网站的运营管理权归爱科来所有。本协议是明确您在访问、浏览和使用爱科来的服务和网站的过程中爱科来和您双方的权利义务基础上所订立的协议。订立本协议的用户，应当是具备完全民事权利能力和完全民事行为能力的自然人。
      </p>
      
      <p class="f14 fw700 mt30">2.不承诺声明</p>
      <p>
        本网站所载的文本、图片、照片、设计、数据、观点、建议、网页或链接（以下简称“资料和信息”）等仅供您参考。爱科来不保证这些资料和信息的准确性、完整性、充分性、及时性和可靠性；不对这些资料和信息的偏差、错误或遗漏承担责任。对于您依赖本网站资料和信息所作出的任何决策,采取的任何行动，由您个人或您所代表的机构承担风险，爱科来不承担责任。爱科来保留随时对本网站上的资料和信息进行修改，更新及删除，而无需另行通知的权利。如果您不同意更新后的本协议，您可以停止使用本网站及服务。如您继续使用，则视为您已经接受本协议的所有更新。本网站保留的有与第三方网站或网址的链接目的是为您提供方便，并不表示爱科来对该链接所显示的任何产品和服务的推荐、宣传或广告目的爱科来不保证这些链接上所提供的任何和资料和信息的准确性、完整性、充分性、及时性和可靠性。
      </p>
      <p class="f14 fw700 mt30">3.注册信息提供及账号管理</p>
      <p>
        您注册爱科来网站的账号所有权归属爱科来所有，您在注册账号时，应当按注册页面引导提供真实、准确、最新和完整的个人资料，使用公司名称、本人姓名、手机号码、邮箱地址等信息进行实名验证。您在阅读并同意本协议且完成全部注册程序后，即可设置爱科来网站的账号密码、成为爱科来的注册用户，可以根据爱科来给予您的权限浏览、下载、存储在爱科来的服务和网站上相应的部分信息，注册用户在爱科来网站上可访问和使用的权限由爱科来根据业务实际情况判断后设置。
      </p>
      <p>
       您应当按以下规则管理账号： 
      </p>
      <p>
       （1）授权用户仅可以使用本人注册的账号访问和使用被授权的部分内容和服务，被授权的部分内容和服务仅供您本人使用； 
      </p>
      <p>
       （2）自行维护您的账号、密码等信息的机密性，并且您应当由您本人通过使用账号密码访问网站或接受服务等； 
      </p>
      <p>
       （3）不得以虚假信息或其他违法和不良信息进行注册；
      </p>
      <p>
       （4）不得将账号、密码转让或出借予他人使用；当发生或发现您的密码或帐户被他人使用时，您应当将该等情况立即通知爱科来；
      </p>
      <p>
       （5）若违反相关法律法规和本协议各项规定，爱科来有权对您的账号单方采取暂停使用、注销登记等措施；
      </p>
      <p>
      （6）若网站被终止或取消，爱科来可以从服务器上永久地删除该用户的数据；网站终止或取消后，爱科来没有义务向用户返还或恢复任何数据。
      </p>
       <p class="f14 fw700 mt30">4.  用户承诺和授权</p>
      <p>
       您在使用爱科来的账号、服务、及网站时承诺并确认：
      </p>
      <p>
       （1）遵守中国法律和法规，遵守所有与网络服务有关的网络协议、规定和程序； 
      </p>
      <p>
       （2）不得将爱科来网站以及网站提供的任何内容信息披露给任何第三方，不得将其使用于非法目的，不得利用爱科来的网站和服务进行任何可能对互联网的正常运转造成不利影响的行为； 
      </p>
      <p>
       （3）不得利用爱科来的网站和服务进行任何不利于爱科来的行为；
      </p>
      <p>
       （4）您应当对其所提供内容的真实性、合法性负责，不得出现违法和不良信息；
      </p>
      <p>
       （5）您知悉并授权爱科来在合法、正当、必要的情况下使用或与关联公司、相关经销商、相关供应商、公司顾问等第三方同步您向爱科来提供的信息，以为您提供相关服务；
      </p>
      <p>
       （6）您同意接收爱科来推送的信息、短信、电子邮件、电话等联系；
      </p>
      <p>
       （7）您上传到爱科来的网站中除个人信息以外的文字、图片和音视频等内容，爱科来具有免费使用权。 
      </p>
      
       <p class="f14 fw700 mt30">5.访问网站和内容的安全性、密码和手段</p>
      <p>
       （1）您同意不以任何可能损坏、禁用、过载或损坏任何本网站帐户、计算机系统或网络的方式访问或使用网站；
      </p>
      <p>
      （2）您同意不尝试未经授权访问网站的任何部分或任何本网站帐户、计算机系统或网络。
      </p>
      <p>
       （3）您同意不干扰或试图干扰网站或任何本网站帐户、计算机系统或网络的正常工作。
      </p>
      <p>
       （4）您同意在未经爱科来明确书面许可的情况下，不得使用任何机器人、间谍或其他自动手段访问网站或任何本网站帐户、计算机系统或网络。
      </p>
       <p class="f14 fw700 mt30">6.赔偿</p>
      <p>
        您违反法律法规行为以及本协议条款及其相关附加规则时，爱科来有权终止向您提供网站及相关服务，并保留要求您赔偿由此引起的任何索赔、责任、损害、损失或费用等追责权利，包括直接损失和合理的律师费用等间接损失，以及您侵犯任何第三方权利，如知识产权等权利所产生的相关费用等。
      </p>
        <p class="f14 fw700 mt30">7.终止使用</p>
      <p>
        爱科来可自行决定随时终止、停止提供或限制对服务的任何领域或通过服务提供的内容。您同意爱科来可随时自行决定终止或限制您访问或使用服务或任何内容。
      </p>
        <p class="f14 fw700 mt30">8.免责声明</p>
      <p>
        （1）爱科来明确声明不提供任何类型的所有保修，无论是明示还是暗示，包括但不限于特定目的的适用性，以及与网站及通过网站所提供的所有内容无关的保证。爱科来不保证：（a） 网站提供的服务或内容将满足您的要求 （b）使用网站或通过网站提供的任何内容可能获得的结果将是准确或可靠的（c）您通过网站使用获得的任何内容的质量将满足您的期望。
      </p>
      <p>
       （2）通过使用本网站访问、下载或以其他方式获得的任何内容，请自行决定和风险使用。爱科来为加强网络安全管理和风险防控进行检修，或因技术原因和网络中断等因素，可能会造成您的实时操作无效或暂停，爱科来对下载或使用内容导致的计算机系统损坏或数据丢失不承担责任。 
      </p>
      <p>
       （3）因不可抗力、黑客攻击、病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方瑕疵等原因而导致的用户信息泄漏、网站中断及其他损失的，您同意爱科来不承担由此产生的任何费用或损害赔偿。
      </p>
      <p>
       （4）爱科来保留随时对网站或通过本网站提供的服务和内容进行更改或更新以及监控其使用的权利，而无需通知。
      </p>
      <p>
       （5） 在任何情况下，爱科来不对您或任何第三方因您访问或使用所提供的网站或通过网站所提供的任何内容而引起的任何直接、间接、偶然、特殊或间接损害，或因您或任何第三方所遭受的利润、收入、数据或数据使用损失而承担任何损害赔偿。
      </p>
      <p>
      （6） 您必须自行承担使用网站和服务所需的费用，包括但不限于网络费用或使用 3G/4G/5G 蜂窝无线通信所产生的费用等，您同意爱科来不承担由此产生的任何费用或损害赔偿。
      </p>
      <p>
        （7）因您所发布的内容涉嫌构成违法和不良信息，爱科来有权不经通知单方面采取删除、屏蔽或断开连接等措施。
      </p>
      <p>
        （8）您使用爱科来的网站和服务过程中导致爱科来或任何其他第三方产生损失的，您应当自行承担全部责任。
      </p>
      <p>
       （9）您使用爱科来的网站和服务而产生的任何其他后果。
      </p>

      <p class="f14 fw700 mt30">9.排除和限制</p>
      <p>
        如本协议规定的免责声明、排除和限制在中国法律上无效，则不适用，但所有其他条款应保持充分效力。
      </p>
        <p class="f14 fw700 mt30">10.知识产权</p>
      <p>
       a)除特别说明外，本网站在服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归爱科来所有。
      </p>
       <p>
       b)除特别说明外，本网站提供服务时所依托软件的著作权、专利权及其他知识产权均归爱科来所有。
      </p>
       <p>
        c)本网站不保证为向用户提供便利而设置的外部链接的准确性和完整性，若由此而引起的版权问题或其他问题，请致电本网站售后客服电话：400-8203867,如经核实会立即予以删除或更改。
      </p>
       <p>
       d)本网站页面所有信息受《中华人民共和国著作权法》及相关法律法规和中国加入的所有知识产权方面的国际条约的保护，您只有在获得爱科来或其他相关权利人的授权之后才能使用这些内容。未经爱科来或相关权利人书面许可，您不得以任何形式进行使用或创造相关衍生作品，也不得披露给任何第三方，因您或其他第三人违反本法律声明而引发的任何一切索赔、损害等，爱科来概不负责。
      </p>
        <p class="f14 fw700 mt30">11.版权/商标</p>
      <p>
        爱科来在网站上为您使用服务而提供的所有内容（爱科来集团外的第三方链接除外）的版权归ARKRAY和/或其附属公司（包括爱科来）所有，ARKRAY和/或其附属公司（包括爱科来）保留所有权利。
      </p>
      <p>
       爱科来、京都、arkray等是ARKRAY及其附属公司（包括爱科来）的注册商标。网站上出现的其他名称可能是其各自所有者的商标。未经许可复制、发行、修改、公开展示或公开展示受版权保护的作品，可能侵犯著作权人的权利。您同意，您不会侵犯爱科来的知识产权或他人的知识产权。您不得从爱科来软件、第三方软件或您通过本网站访问的任何产品或内容的任何原件或副本中删除、污损或转载任何版权、商标、徽标或其他所有权通知。
      </p>
       <p class="f14 fw700 mt30">12.隐私声明</p>
      <p>
       爱科来不会公开、编辑或透露用户保存在本网站中的个人信息，除非有法律许可要求或爱科来在诚信的基础上认为在以下情况下透露这些信息是必要的，具体隐私条款请参照爱科来的隐私声明 ：
      </p>
      <p>
        （1）事先获得用户的明确授权；
      </p>
      <p>
        （2）根据有关的法律法规要求；
      </p>
      <p>
        （3）按照相关政府主管部门的要求；
      </p>
      <p>
        （4）为维护社会公众的利益；
      </p>
      <p>
        （5）为维护本平台的合法权益。
      </p>
      <p>
        （6）符合其它相关要求。
      </p>
       <p class="f14 fw700 mt30">13.前瞻性和警告性陈述</p>
      <p>
        爱科来的网站中列出的某些陈述可能构成适用法律意义上的前瞻性和警告性陈述。虽然这些前瞻性陈述代表了我们目前对未来前景的判断，但它们面临可能导致实际结果存在重大差异的风险和不确定性。我们提醒您不要过分依赖这些前瞻性陈述，这些陈述仅在网站中列出此类声明的日期时反映了我们当时的意见。请记住，我们没有义务根据新信息或未来事件修订或公开发布对这些前瞻性声明的任何修订结果。
      </p>
       <p class="f14 fw700 mt30">14.豁免和可分离性</p>
      <p>
       爱科来未能行使或执行本协议中的任何权利或规定，不应构成对此类权利或条款的放弃。如果发现这些协议的任何部分或条款无法执行，则可以修改这些部分或条款，使本协议成为可修改的合法和可执行条款，本协议的其他条款仍应有效并由约束力。
      </p>
       <p class="f14 fw700 mt30">15.适用法律/仲裁</p>
      <p>
        与您访问或使用网站或通过网站所提供的服务和内容有关的所有事项均受中国人民共和国法律管辖。当事人之间可能因使用、使用服务或内容而可能出现的所有争议或分歧，当事人双方可以通过和解或者调解解决，当事人不愿和解、调解或者和解、调解不成的，采用向爱科来所在地人民法院起诉之方式解决。
      </p>
      <p class="f14 fw700 mt30">16.  其他</p>
      <p>
        （1） 爱科来对本协议及网站上的所有内容享有修订及法律范围内的解释权。
      </p>
      <p>
        （2） 本协议项下的服务对于您所有的通知均可以通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知与发送之日视为送达收件人。
      </p>
      
    </div>

    <p class="title f18 fw700 textc mt30">
      <!-- {{ $t("regInstruct.RegistrationForNewUsers") }} -->
      Privacy Policy
    </p>
    <p class="title f18 fw700 textc pb30">
      <!-- {{ $t("regInstruct.RegistrationForNewUsers") }} -->
      个人信息保护政策
    </p>
    <div>
      <p class="texti2">
        爱科来国际贸易（上海）有限公司（“爱科来”、“我们”、“本公司”）将依法妥善处理您的个人信息。请在向本公司提交个人信息之前，阅读、了解本《个人信息保护政策》（下文简称“本政策”）。
      </p>
      <p class="texti2">
        本公司保留不时更新或修改本政策的权利。如果我们的隐私政策变更，我们会将最新版隐私政策发布在这里。如果我们对隐私政策做出了重大变更，我们还可能会通过不同渠道向您发送变更通知。
      </p>
      <p class="f14 fw700 mt30">我们制定本政策的目的在于帮助您了解以下内容：</p>
       <!-- <p class="texti2">
       本隐私声明最近更新日期： 2021年4月2日
      </p> -->
      <p class="f14 mt30">1.收集的个人数据的内容及其收集方法</p>
      <p class="f14 mt30">2.个人数据的使用目的</p>
      <p class="f14 mt30">3.个人数据的保管</p>
      <p class="f14 mt30">4.对个人数据的共享</p>
      <p class="f14 mt30">5.个人数据保护措施</p>
      <p class="f14 mt30">6.个人作为数据主体拥有的权利</p>
      <p class="f14 mt30">7.关于Cookie的使用</p>
      <p class="f14 mt30">8.本公司的联系方式</p>
       <p class="title f14 fw700 mt30">
      １．个人信息的内容及收集方法
      </p>
      <p>
       “个人信息”是本公司为业务目的所收集的姓名、年龄、出生年月日、性别、住址、电话号码、邮件地址、工作单位等有关个人的信息，可以通过其中的一个或多个信息的组合来识别特定的个人。
      </p>
      <p>
      本公司仅会出于本声明所述目的收集和使用您的个人信息，我们可能收集的个人信息包括：
      </p>
      <p>
        您的姓名、公司名称、邮箱、联系电话等。
      </p>
      <p>
      	收集个人信息的时候，我们会告知使用目的以及问询窗口等。
      </p>

      <p class="title f14 fw700 mt30">
      2．个人数据及其使用目的
      </p>
      <p class="texti2">
       本公司主要持有的个人数据及其使用目的如下。
      </p>
      <div>
      <table style="margin-bottom:50px;" align="left" border="1" cellpadding="6"  cellspacing="0" >
        <tbody align="center">
          <el-row>
              <th style="border-top:none;width:30vw;border-left:none;">收集场景</th>
		  			  <th style="border-top:none;width:30vw;">主要持有的个人数据</th>
		  			  <th style="border-top:none;text-align:left;width:39vw;">主要使用目的</th>
          </el-row>
          <el-row>
              <th width="200" style="border-left:none;width:30vw;">注册账户</th>
		  			  <th style="width:30vw;">姓名、密码、邮箱、联系电话</th>
		  			  <th style="text-align:left;width:39vw;">
                <p>·进行登录代理商权限管理</p>
                <p>·售后培训通知</p>
                <p>·培训前和/或培训后考试成绩记录、收集和分析（每次培训完成后）</p>
                <p>·培训考核合格证书制作、发放（每次培训完成后）</p>
                <p>·与您沟通，回复咨询</p>
                </th>
          </el-row>
          <el-row>
              <th width="200" style="border-left:none;width:30vw;">参加培训</th>
		  			  <th style="width:30vw;">姓名、密码、邮箱、联系电话、培训成绩</th>
		  			  <th style="text-align:left;width:39vw;">
                ·培训成绩记录、收集和分析
                </th>
          </el-row>
        </tbody>
      </table>
      </div>
      <p class="texti2">
      ※培训成绩在其他信息删除后可能会予以保留，但会进行匿名化处理。
      </p>
      <p class="title f14 fw700 mt30">
      3．个人信息的保管
      </p>
      <p>
       我们在中华人民共和国境内运营中收集和处理的个人信息，将存储在中华人民共和国境内。我们将会在达成本政策所述目的所需的期限内保留您的个人信息，除非按照法律要求或许可需要延长保留期或受到法律的允许。
      </p>
      <p class="title f14 fw700 mt30">
      4．个人数据的共享
      </p>
      <p>
       （1）本网站由本公司委托安徽云蓝信息科技有限公司设计、开发并维护。基于其履行网站维护需要，将会接触到您的个人信息。我们要求其履行保密义务。除此之外，如果我们委托其处理个人信息的，我们会与其签署委托处理协议，要求其按照协议的约定、本政策以及适用的数据保护法律的要求来处理个人信息。
      </p>
      <p>
       （2） 本网站收集的信息将存储在阿里云服务器中。阿里云作为云服务提供商，未经本公司许可或者执行明确的法律法规要求外，不会对您的个人数据进行任何非授权的使用或披露。详细了解阿里云的《隐私权政策》（云安全访问服务隐私权政策 (aliyun.com)）。如果我们委托其处理个人信息的，我们会与其签署委托处理协议，要求其按照协议的约定、本政策以及适用的数据保护法律的要求来处理个人信息。
      </p>
      <p>
       （3）我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，共享您的个人信息。
      </p>
      <p>
       （4）除以上情形外，除非依法取得您的同意，我们不会公开披露或处理您的个人信息。
      </p>
     <p class="title f14 fw700 mt30">
      5. 个人数据保护措施
      </p>
      <p>
       我们采用适当的物理、管理和技术保障措施来保护您的个人信息，通过妥善管理个人信息，致力于防止个人信息丢失、破坏、篡改、泄露以及非法获取个人信息。我们会尽力保护您的个人信息，但是请注意任何安全措施都无法做到无懈可击。
      </p>    
      <p class="title f14 fw700 mt30">
      6. 个人作为数据主体拥有的权利
      </p>
      <p>
       您应确保提交的所有个人信息都准确无误。本公司会尽力维护个人信息的准确和完整，并及时更新这些数据。
      </p>
      <p>
       当适用的法律要求的情况下，您可能（1）有权查阅、复制我们持有的关于您的特定的个人信息；（2）要求我们更新或更正您的不准确的个人信息；（3）拒绝或限制我们使用您的个人信息；（4）要求我们删除您的个人信息；（5）要求我们对个人信息处理规则进行解释说明。
      </p>
      <p>
       当适用的法律要求的情况下，当本公司基于您的同意处理您的个人信息时，您还有权随时撤销您的同意。但撤销同意不会影响撤销前我们基于您的同意处理您个人信息的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人信息。
      </p>
      <p>
       如果您想行使相关的权利，可以通过第8条所列联系方式反馈给我们。为保障安全，您可能需要提供书面请求。如果我们有合理依据认为这些请求存在欺骗性、无法实行或损害他人隐私权，我们则会拒绝处理请求。
      </p>
      <p class="title f14 fw700 mt30">
     7. 关于Cookie的使用
      </p>
      <p>
       为确保网站正常运转，我们有时会在计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。
      </p>
      <p>
       本公司启用Cookie的目的在于改善用户体验。借助于 Cookie，网站能够记住用户的单次访问（使用会话 Cookie）或多次访问（使用永久 Cookie）。借助于 Cookie，网站能够保存设置，例如计算机或移动设备的语言、字体大小和其他浏览偏好。这意味着，用户无需在每次访问时重新配置用户偏好设置。本公司不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
      </p>
      <p class="title f14 fw700 mt30">
     8. 批评建议等联络方式
      </p>
      <p>
        如果您对公司处理个人信息有任何批评或建议，请联系：      </p>
      <p>
      	地址    ：上海市浦东新区金科路2889弄3号长泰大厦C座202室
      </p>
      <p>
       电话    ：400-8203867/021-5081-2554
      </p>
      <p>
      	联系人  ：售后服务部
      </p>
      <p>
      	邮箱    ：<span style="color: #4e4eeb;">service_site@arkray.co.jp</span>
      </p> 
      <p style="text-align:right" class="mt30 fw700">
       爱科来国际贸易（上海）有限公司
      </p>
      <p style="text-align:right" class="fw700">
       2021年12月13日
      </p>
    </div>


    <div class="textc mt20">
      <el-button type="primary" class="wp50" @click="goBack">{{
        $t("agreeTip")
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      seeFlag: false,
      sendData: {},
    };
  },
  methods: {
    handleScroll() {
      var that = this;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight) {
        //写后台加载数据的函数 一定要用that
        console.log("到底了");
        that.seeFlag = true;
        that.$store.dispatch("app/setSeeFlag", that.seeFlag);
      }
    },
    goBack() {
      this.seeFlag = true;
      this.$store.dispatch("app/setSeeFlag", this.seeFlag);
      this.$router.push({
        path: "/register",
        query: { sendData: this.sendData },
      });
    },
  },
  mounted() {
    //给window添加一个滚动滚动监听事件
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    this.sendData = this.$route.query.sendData;
  },
};
</script>

<style scoped>
.tips {
  background-color: #fff;
  padding: 20px 13px;
}
</style>